@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-color: var(--secondary) var(--primary);
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 25%,
    rgba(166, 255, 220, 1) 50%,
    rgba(0, 133, 113, 1) 100%
  );
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 133, 113, 1);
  border-radius: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.faq-answer {
  background-color: lightgray;
}
