@keyframes tonext {
    75% {
      left: 0;
    }
    95% {
      left: 100%;
    }
    98% {
      left: 100%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes tostart {
    75% {
      left: 0;
    }
    95% {
      left: -300%;
    }
    98% {
      left: -300%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }
    97% {
      scroll-snap-align: none;
    }
    99% {
      scroll-snap-align: none;
    }
    100% {
      scroll-snap-align: center;
    }
  }
  

  
  ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .carousel {
    padding-top: 584px;
    filter: drop-shadow(0 0 10px 0 #0003);
    perspective: 100px;
  }
  
  .carousel__viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x:hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    height: 584;
  }
  
  .carousel__slide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    background: url("../../../public/media/Rectangle.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 584px;
  }
  
  .carousel__slide:nth-child(even) {
    background: url("../../../public/media/Rectangle41.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 584px;
  }
  
  .sn-herotxt{
    text-align: center;
    align-items: center;
    z-index: 1;

  }

  .carousel__slide:before {
    transform: translate3d(-50%,-40%,70px);
  }
  
  .carousel__snapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  }
  
  @media (hover: hover) {
    .carousel__snapper {
      animation-name: tonext, snap;
      animation-timing-function: ease;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  
    .carousel__slide:last-child .carousel__snapper {
      animation-name: tostart, snap;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel__snapper {
      animation-name: none;
    }
  }
  
  .carousel:hover .carousel__snapper,
  .carousel:focus-within .carousel__snapper {
    animation-name: none;
  }
  
  .carousel__navigation {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
  }
  
  .carousel__navigation-list,
  .carousel__navigation-item {
    display: inline-block;
  }
  
  .carousel__navigation-button {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #333;
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;
  }
  
  .carousel::before,
  .carousel::after,
  .carousel__prev,
  .carousel__next {
    position: absolute;
    top: 0;
    margin-top: 37.5%;
    width: 4rem;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    outline: 0;
  }
  
  .carousel::before,
  .carousel__prev {
    left: -1rem;
  }
  
  .carousel::after,
  .carousel__next {
    right: -1rem;
  }
  
  /*.carousel::before,
  .carousel::after {
    content: '';
    z-index: 1;
    background-color: #333;
    background-size: 5px 5px;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;
    font-size: 5px;
    line-height: 4rem;
    text-align: center;
    pointer-events: none;
  }
  
  .carousel::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
  }
  
  .carousel::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
  }*/
  